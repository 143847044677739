import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "OpenAPI",
  "path": "/docs/openapi-description",
  "date": "2020-03-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`OpenAPI description`}</h1>
    <p>{`The enablehr REST API is fully described in an OpenAPI 3.0 compliant document.`}</p>
    <h2>{`About OpenAPI descriptions`}</h2>
    <p>{`OpenAPI is a standard specification for describing REST APIs. OpenAPI
descriptions allow both humans and machines to discover the
capabilities of an API without needing to first read documentation or
understand the implementation. enablehr has made its REST API publicly
available as an OpenAPI 3.0 compliant document.`}</p>
    <h2>{`Getting the enablehr OpenAPI description`}</h2>
    <p>{`You can find the description in the open source REST API OpenAPI
Description repository.`}</p>
    <p>{`We provide the description in two formats. The bundled version works
for most cases, as it includes OpenAPI components for reuse and
readability. If your tooling does not support inline references to
components, we also provide a fully dereferenced version.`}</p>
    <h2>{`Using the enablehr OpenAPI description`}</h2>
    <p>{`There are many uses for an OpenAPI description. For example, you
could:`}</p>
    <ul>
      <li parentName="ul">{`Generate your own API client.`}</li>
      <li parentName="ul">{`Validate and test a enablehr REST API integration.`}</li>
      <li parentName="ul">{`Explore and interact with the enablehr REST API using third-party tools, such as Insomnia or Postman.`}</li>
    </ul>
    <p>{`For example, enablehr uses the REST OpenAPI description to generate
the enablehr REST API reference documentation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      